import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import VisuallyHidden from '../VisuallyHidden';

import * as RecentPostsStyles from './recent-posts.module.scss';

function Post({ post }) {
  const {
    title,
    publishedDate,
    prettyPublishedDate,
    modifiedDate,
    prettyModifiedDate,
  } = post.frontmatter;

  const hasUpdated = modifiedDate !== publishedDate;
  const displayDate = hasUpdated ? prettyModifiedDate : prettyPublishedDate;
  const attributeDate = hasUpdated ? modifiedDate : publishedDate;

  return (
    <article className={RecentPostsStyles.post}>
      <span aria-hidden="true">&gt;</span>
      <Link to={`/blog/${post.fields.slug}/`}>
        <h3>{title}</h3>
        <p>
          <VisuallyHidden>
            {hasUpdated ? 'Modified: ' : 'Published: '}
          </VisuallyHidden>
          <time dateTime={attributeDate}>{displayDate}</time>
        </p>
      </Link>
    </article>
  );
}

Post.propTypes = {
  post: PropTypes.object.isRequired,
};

// assumes post is in order by date
export default function Posts({ posts }) {
  return (
    <section aria-labelledby="recent-posts">
      <h2 id="recent-posts">Recent Posts</h2>
      {posts.map((post) => (
        <Post key={post.id} post={post} />
      ))}
      <Link to="/blog/">See all posts</Link>
    </section>
  );
}

Posts.propTypes = {
  posts: PropTypes.array.isRequired,
};
