import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Emoji from 'a11y-react-emoji';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import RecentPosts from '../components/RecentPosts';

import * as PageStyles from './page.module.scss';

export default function HomePage({ data }) {
  const posts = data.allMdx.nodes;

  return (
    <Layout>
      <Seo />
      <section aria-labelledby="home" className={PageStyles.introSection}>
        <h1 id="home">
          Hello <Emoji symbol="👋" />!
        </h1>
        <p>
          I'm Andy, a web developer with a focus on JavaScript, React, and
          Jamstack architecture.
        </p>
      </section>
      <RecentPosts posts={posts} />
      <section
        aria-labelledby="projects-showcase"
        className={PageStyles.listSection}
      >
        <h2 id="projects-showcase">Projects Showcase</h2>
        <ul>
          <li>
            <a
              href="https://github.com/AndyN9/payday-calendar-generator"
              target="_blank"
              rel="noopener noreferrer"
            >
              Payday Calendar Generator
            </a>{' '}
            - generate a payday calendar (iCal file) for different payroll
            periods
          </li>
          <li>
            <a
              href="https://github.com/AndyN9/personalfinance-prime-directive"
              target="_blank"
              rel="noopener noreferrer"
            >
              Personal Finance Prime Directive
            </a>{' '}
            - personal income spending flowchart
          </li>
        </ul>
        {/* <Link to="/about/#projects">See all projects</Link> */}
      </section>
    </Layout>
  );
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query HomePageQuery {
    allMdx(
      sort: { fields: frontmatter___modifiedDate, order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
      limit: 3
    ) {
      nodes {
        frontmatter {
          title
          publishedDate
          prettyPublishedDate: publishedDate(formatString: "MMM DD, YYYY")
          modifiedDate
          prettyModifiedDate: modifiedDate(formatString: "MMM DD, YYYY")
        }
        id
        fields {
          slug
        }
      }
    }
  }
`;
